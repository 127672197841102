/* eslint-disable default-case */
import React, { Component } from 'react';
import { Layout, message,Table, Button } from 'antd';
import intl from 'react-intl-universal';
import { Link } from "react-router-dom";
import './Devices.scss';
import Cookies from 'universal-cookie';
import { inject, observer } from 'mobx-react';

const cookies = new Cookies();

const { Header, Content, } = Layout;
@inject('store')
@observer
class StoreList extends Component {
  constructor(props){
    super();
    this.state = {
      domain : global.Api.domain_url(),
      lang : intl.options.currentLocale,
      querystr: "",
      list:[],
      listColumns:[
        {title:intl.get('store_id'),dataIndex:'id'},
        {title:intl.get('store_name'),dataIndex:'name'},
        {title:intl.get('manager'),dataIndex:'manager_name'},
        {title:intl.get('manager_email'),dataIndex:'manager_email'},
        // {title:intl.get('store_email'),dataIndex:'email'},
        {title:intl.get('store_contact'),dataIndex:'contact'},
        {title:intl.get('address'),dataIndex:'address'},

        // {title:intl.get('operation'),key:'actions',
        //   render: ({id,uuid}) => (
        //     <div style={{display: "flex"}}>
        //       {/* <Link to={`/detail/${id}`}>{intl.get('view')}</Link> */}            
        //       <a href="#" onClick={()=>this.unbindDevice(id)}>Detail</a>
        //     </div>
        //   ),
        // },
      ],
      pagination: {},
      loading: false,
    }
  }

  componentDidMount(){
    if (this.state.lang === 'ge'){
      this.setState({
          lang:'de'
      })
    }else if (this.state.lang === 'sp'){
        this.setState({
            lang:'es'
        })
    }else if (this.state.lang === 'ma'){
      this.setState({
          lang:'ms'
      })
    }else if (this.state.lang === 'lt'){
      this.setState({
          lang:'it'
      })
    }else if (this.state.lang === 'tw'){
      this.setState({
          lang:'zh_TW'
      })
    }else if (this.state.lang === 'jp'){
      this.setState({
          lang:'ja'
      })
    }else if (this.state.lang === 'po'){
      this.setState({
          lang:'pl'
      })
    }else if (this.state.lang === 'por'){
      this.setState({
          lang:'pt'
      })
    }else if (this.state.lang === 'dk'){
      this.setState({
          lang:'da'
      })
    }

    this.getStores()
  }

  
  // 获取列表
  getStores(params = {page : 1}){
    this.setState({ loading: true });
    global.Api.getStores({
      ...params
    }).then(res=>{
      if (res.data.code === 0) {
        const pagination = { ...this.state.pagination };
        let _data = res.data.data;
        pagination.total = _data.total;
        pagination.pageSize = 15;
        this.setState({
          loading: false,
          list: _data.stores,
          pagination,
        });
        return
      }
      message.error(res.data.message);      
    })
  }
 
  //搜索
  inputChange(e){
    console.log(e.target.value)
    this.setState({
      querystr:e.target.value
    })
  }
  
  getInputValue(){
    console.log(this.state.pagination);
    if(!this.state.pagination.current){
      this.getStores({
        page: 1,
        search: this.state.querystr,
        per_page: this.state.pagination.pageSize,
      });
    }else{
      this.getStores({
        page: this.state.pagination.current,
        search: this.state.querystr,
        per_page: this.state.pagination.pageSize,
      });
    }
  }
  // 翻页
  handleTableChange = (pagination) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.getStores({
      page: pagination.current,
      per_page: pagination.pageSize,
    });
  }

  // 根据轮胎状态返回颜色
  wheelStatusColor(status) {
    switch (status) {
      case 'low':
        return '#0c0';
      case 'middle':
        return '#f90';
      case 'high':
        return '#ff3300';
    }
  }

  
  render() {
    
    return (
      <Layout className="reports">
        <Header style={{position: 'fixed', zIndex: 1, width: '100%',height:'120px',backgroundColor:'#3385ff',color:"#fff",}}>
          <div style={{marginRight:"20px",color:"#fff"}} >
            <span style={{fontSize:"20px",color:"#fff"}}>Handy Tyre Scanner</span>
          {/* <img class="logo img-fluid" src="https://mobilhubdiagnostics.com/img/logo-1667829529.jpg" alt='' width="228" height="38" /> */}
          </div>
          <Button type="link" style={{marginLeft:"10px",color:"#fff",fontSize:'15px',fontWeight:'500',}} ><Link to="/dashboarddealer">{intl.get('dashboard')}</Link></Button>
          <Button type="link" style={{marginLeft:"10px",color:"#fff",fontSize:'15px',fontWeight:'500',}} ><Link to="/dealerlist">{intl.get('reports')}</Link></Button>
          {/* <Button type="link" style={{marginLeft:"10px",color:"#fff",fontSize:'15px',fontWeight:'500',}} ><Link to="/devices">{intl.get('devices')}</Link></Button> */}
          <Button type="link" style={{marginLeft:"10px",color:"#fff",fontSize:'15px',fontWeight:'500',}} ><Link to="/storelist">{intl.get('storemanagement')}</Link></Button>
          
          <Button type="link" style={{marginRight:"10px",color:"#fff",fontSize:'15px',fontWeight:'500',}} ><Link to="/settingdealer">{intl.get('setting')}</Link></Button>
        </Header>
        <Content style={{ padding: '0 50px', marginTop:'110px'}}>
          <div style={{ background: '#fff', padding: 24}}>
            <button style={{marginLeft: '10px',padding: '2px 12px',border:'none',fontColor:'#fff',fontSize:'18px',fontWeight:'500',height:'40px',width:'200px',borderRadius:'10px 10px 10px 10px',backgroundColor:'#fff'}}>
              <Link to="/addstore">{intl.get('addstore')}</Link>
            </button>
            <Table 
            rowKey={item => item.id}
            dataSource={this.state.list} 
            columns={this.state.listColumns} 
            pagination={this.state.pagination} 
            loading={this.state.loading} 
            onChange={this.handleTableChange} 
            />
          </div>
        </Content>
      </Layout>
    );
  }
}

export default StoreList;
