import { observable, action } from 'mobx';

// 状态管理
class RootStore {  
  //the rootUrl is used for QRCode report url
  // rootUrl = 'http://customer.tyrehubtech.com/'
    
  @observable user = null
  @observable user_type = ''

  @action
  updateUser(user, user_type, callback) {
    this.user = user
    this.user_type = user_type
    callback && callback();
  }

  //获得携带的参数
  getQueryString(name) {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    let r = window.location.search.substr(1).match(reg);
    if (r != null) return decodeURI(r[2]); return null;
  }

  isMobileNumber(phone) {
    let flag = false;
    let message = "";
    let myreg = /^(((13[0-9]{1})|(14[0-9]{1})|(17[0-9]{1})|(15[0-3]{1})|(15[4-9]{1})|(18[0-9]{1})|(199))+\d{8})$/;
    if (phone == '') {
      // console.log("手机号码不能为空");
      message = "手机号码不能为空！";
    } else if (phone.length != 11) {
      //console.log("请输入11位手机号码！");
      message = "请输入11位手机号码！";
    } else if (!myreg.test(phone)) {
      //console.log("请输入有效的手机号码！");
      message = "请输入有效的手机号码！";
    } else {
      flag = true;
    }
    if (message != "") {
      alert(message);
    }
    return flag;
  }

}

export default new RootStore();
